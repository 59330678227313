import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import LanguageIcon from "@mui/icons-material/Language";
import GridViewIcon from "@mui/icons-material/GridView";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ApiIcon from "@mui/icons-material/Api";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import EngineeringIcon from '@mui/icons-material/Engineering';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { CloudUpload } from "@mui/icons-material";
const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Our Serivces",
    paragraph:
      "From IT strategy consulting and comprehensive technology roadmaps to the end-to-end development of scalable solutions, Quantek delivers a full-cycle software development services that adapt seamlessly to your project requirements and business needs.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <LanguageIcon
                      fontSize="large"
                      alt="Features tile icon 05"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Custom Web Development</h4>
                  <p className="m-0 text-sm">
                    Our custom web development solutions accelerate workflows,
                    boost revenues, and optimize business operations from
                    concept-to-code and development-to-deployment.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <GridViewIcon
                      fontSize="large"
                      alt="Features tile icon 06"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div
                  className="features-tiles-item-content"
                  data-reveal-delay="200"
                >
                  <h4 className="mt-0 mb-8">Blockchain</h4>
                  <p className="m-0 text-sm">
                    From cryptocurrencies to supply chain management,
                    Blockchain’s decentralized, distributed, and public ledger
                    offers the highest level of security, transparency, and
                    traceability.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <FactCheckIcon
                      fontSize="large"
                      alt="Features tile icon 06"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div
                  className="features-tiles-item-content"
                  data-reveal-delay="200"
                >
                  <h4 className="mt-0 mb-8">QA & Testing</h4>
                  <p className="m-0 text-sm">
                    Quality Assurance is all about creating the best possible
                    product for your customers. A proper QA & Testing process
                    reveals valuable insights, mitigates downtime, and improves
                    user experience.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <ApiIcon
                      fontSize="large"
                      alt="Features tile icon 04"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">API Development</h4>
                  <p className="m-0 text-sm">
                    We develop reliable, well-documented, and easy-to-consume
                    APIs that enable flexible integrations and customization of
                    existing software products.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <AutoFixHighIcon
                      fontSize="large"
                      alt="Features tile icon 01"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">UX/UI Design</h4>
                  <p className="m-0 text-sm">
                    Leverage the latest UI/UX technologies, architectures, and
                    trends to design responsive & scalable apps that transform
                    customer experiences across multiple channels.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <CloudUpload
                      fontSize="large"
                      alt="Features tile icon 02"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Cloud Computing</h4>
                  <p className="m-0 text-sm">
                    Cloud computing has become an essential driver of innovation
                    and business transformation. Cloud-based technologies
                    rapidly increase business agility, lower costs, and reduce
                    IT complexity.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <EngineeringIcon
                      fontSize="large"
                      alt="Features tile icon 02"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Application Maintenance</h4>
                  <p className="m-0 text-sm">
                    Our app maintenance & modernization services are designed to
                    ensure the scalability, performance, and sustainability of
                    your entire software infrastructure.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <WidgetsIcon
                      fontSize="large"
                      alt="Features tile icon 03"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">System Integrations</h4>
                  <p className="m-0 text-sm">
                    Our software integration engineers adopt new technologies
                    and processes to overcome challenges relating to everything
                    from architectural design to testing to execution.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
